import { api } from ":mods";
import { ENDPOINTS } from "../const";

export async function getSchedules() {

      return api
		.get(ENDPOINTS.SCHEDULES_LIST)
		.then(async (res) => {
                  const schedulesList  = res.data;
			if (!schedulesList) return Promise.reject();
			return Promise.resolve(schedulesList);
		})
		.catch((err) => {})

}